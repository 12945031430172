import Carousel from 'react-grid-carousel'
import '../styles/carousel.scss';
import test1 from '../images/amanda_roger.webp';
import test2 from '../images/Will-Henderson.webp';
import test3 from '../images/Cameron-Macdonald.webp';
import test4 from '../images/Gary-Wilson.webp';
import test5 from '../images/David-Smart.webp';
import test6 from '../images/Kristoffer.webp';
import RD from '../images/Richard_Dickson.webp';
import AL from '../images/andrew_lang.webp';
import KB from '../images/kb.webp';
import MI from '../images/MI.webp';
import SL from '../images/SL.webp';
import FK from '../images/FK.webp';

export default function Testimonials(props) {

  return (
    <section className='carousel__main' style={{ backgroundColor: props.background }}>
      <h2>Hear from our clients</h2>
      <Carousel cols={3} rows={1} gap={10} loop hideArrow={false}>
        <Carousel.Item >
          <img width="20%" src={test1} className='carousel--image' alt="testimonial image" />
          <h4>Amanda & Rodger Boyd</h4>
          <p className="carousel--text">“Since working with Paul and using the strategies taught, we’ve just secured our first off market deal. Valued at £63,000 and secured it for £55,000. This is property will give us a 12% Yield and is going straight into the portfolio.”</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={test2} className='carousel--image' alt="testimonial image" />
          <h4>Will Henderson</h4>
          <p className="carousel--text">“After attending the workshop with Paul and the team, I used what I learned and secured my very first deal at a massive 38% discount. Structuring this the way Paul taught me, I’ms selling it on to an investors and making £10,000 from this one deal.”</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={test3} className='carousel--image' alt="testimonial image" />
          <h4>Cameron Macdonald</h4>
          <p className="carousel--text">“It’s only been 2 months since starting from nothing and I’ve just sold my first off market deal to an investor and made £8,000 profit. Secured my 2nd deal which will complete this weekend. Big thanks to the team!”</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={test4} className='carousel--image' alt="testimonial image" />
          <h4>Gary Wilson</h4>
          <p className="carousel--text">“<strong>Over 50 off market leads in 2 weeks</strong> - After struggling to find good deals, I attended the PMW office 2 weeks (hat’s off to the guys for a great event!). I’ve now generated 50 off market leads following what I learned. I’ve made over £10,000 from the ones I’ve had contact with and still got more to reach out to.”</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={test5} className='carousel--image' alt="testimonial image" />
          <h4>David Smart</h4>
          <p className="carousel--text">“<strong>£69,000 profit from 2 deals</strong> - in the 10 months since working with PMW I’ve sourced, 20 deals for my investors. I’ve kept another 2 for myself making £69,000 from doing them myself. I’ve now left my job and went full time in property.”</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={test6} className='carousel--image' alt="testimonial image" />
          <h4>Kristoffer Dalziel</h4>
          <p className="carousel--text">"I've focused on deal trading as my main strategy for the last 18 months. Using what I learned from Paul and the team I've been able to package up 162 deals so far generating an income over £648,000. This doesn't even account for deals I've kept for my own flips or deals I've kept for my own portfolio."</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={RD} className='carousel--image' alt="testimonial image" />
          <h4>Richard Dickson</h4>
          <p className="carousel--text">"<strong>Made an extra £19,000 on 2 deals</strong> - I’ve re structured some of my deals since this course and have made an extra £7,000 on one, and an extra £12,000 on the other."</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={AL} className='carousel--image' alt="testimonial image" />
          <h4>Andrew Lang</h4>
          <p className="carousel--text">"<strong>£9,000 from one deal</strong> - There’s so much content shared. Using what we learned we took a deal from a normal sourcing fee and turned it into a £9,000 deal. We’ve also made money from deals that initially we didn’t think worked until using what we learned on the course."</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={KB} className='carousel--image' alt="testimonial image" />
          <h4>Kyle Black</h4>
          <p className="carousel--text">"<strong>I made £7,000 on my last deal</strong> - Being inexperienced in property and low on funds, deal trading was where I started, I just followed the step by step guidance from PMW and have done 8 deals now, banking £7,000 on my last one."</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={MI} className='carousel--image' alt="testimonial image" />
          <h4>Mark Innes</h4>
          <p className="carousel--text">"<strong>£5,000 in my first week</strong> - After attending the deal trading course earlier this week I have already signed up my first investor who paid me £5,000 upfront!"</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={SL} className='carousel--image' alt="testimonial image" />
          <h4>Steven Lang</h4>
          <p className="carousel--text">"<strong>£12,000 in 2 months</strong> - After only 2 months of using this strategy I made £12,000 form my first packaged deal. Deal trading isn’t even my main property strategy. The best thing is that the tools and techniques I used to package this deal were so professional the investor now wants to joint venture on future projects with me."</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={FK} className='carousel--image' alt="testimonial image" />
          <h4>Fraser Kelly</h4>
          <p className="carousel--text">"<strong>Now gone full time in my property business</strong> - Massive thanks to the PMW team for the guidance and support. I have now went full time in my property business"</p>
        </Carousel.Item>
        {/* ... */}
      </Carousel>
    </section>
  );
}