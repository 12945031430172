import '../styles/faq.scss';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';

export default function FAQ(props) {
  const [selectTab, setSelectTab] = useState('')

  const handleFaq = (tab) => {
    if (tab === selectTab) {
      setSelectTab('')
    } else {
      setSelectTab(tab)
    }
  }

  return (
    <section className="FAQ__main" style={{ backgroundColor: props.background }}>
      <h2>Frequently Asked Questions</h2>
      <hr />
      <section className='faqs'>
        <h3 onClick={() => handleFaq('1')}>How soon do I get access? {selectTab === '1' ? <KeyboardArrowDownIcon className='faq--icon' /> : <KeyboardArrowRightIcon className='faq--icon' />}</h3>
        {selectTab === '1' ? (
          <p>You’ll receive instant access to our private vault, where all the online modules, additional bonuses,
            and resources are waiting for you.</p>
        ) : null}
        <hr />
        <h3 onClick={() => handleFaq('2')}>Where does the programme take place? {selectTab === '2' ? <KeyboardArrowDownIcon className='faq--icon' /> : <KeyboardArrowRightIcon className='faq--icon' />}</h3>
        {selectTab === '2' ? (
          <p>The entire programme is online, so you can participate from home or work at your own pace. All
            materials are accessible through our online portal on any device. Additionally, you have the option
            to attend a live in-person workshop at our training academy in Glasgow, during your membership.</p>
        ) : null}
        <hr />
        <h3 onClick={() => handleFaq('3')}>How long do I have access to the course? {selectTab === '3' ? <KeyboardArrowDownIcon className='faq--icon' /> : <KeyboardArrowRightIcon className='faq--icon' />}</h3>
        {selectTab === '3' ? (
          <p>You'll have access to the online course for a full year, allowing you plenty of time to go through the
            15+ hours of content at your own pace and attend a live 2-day workshop if you wish.</p>
        ) : null}

        <hr />
        <h3 onClick={() => handleFaq('4')}>How do I access the course content? {selectTab === '4' ? <KeyboardArrowDownIcon className='faq--icon' /> : <KeyboardArrowRightIcon className='faq--icon' />}</h3>
        {selectTab === '4' ? (
          <p>Upon purchase, you will receive login details via email. One email will be an instruction email from
            PMW, and another will be from the Skool platform with your login details. Use the login details to
            access the course content through our online portal, available on any device.</p>
        ) : null}
        <hr />
        <h3 onClick={() => handleFaq('5')}>How do I work my way through the course? {selectTab === '5' ? <KeyboardArrowDownIcon className='faq--icon' /> : <KeyboardArrowRightIcon className='faq--icon' />}</h3>
        {selectTab === '5' ? (
          <p>The Welcome Video in the online portal will show you all of the sections of the programme and
            how to work your way through them in the best way.</p>
        ) : null}
        <hr />
        <h3 onClick={() => handleFaq('6')}>Is the content available on mobile devices? {selectTab === '6' ? <KeyboardArrowDownIcon className='faq--icon' /> : <KeyboardArrowRightIcon className='faq--icon' />}</h3>
        {selectTab === '6' ? (
          <p>Yes, all course materials are fully accessible on mobile devices through our app, as well as on
            tablets and desktops, allowing you to learn conveniently from anywhere.</p>
        ) : null}
        <hr />
        <h3 onClick={() => handleFaq('7')}>Do I get updates to the course content? {selectTab === '7' ? <KeyboardArrowDownIcon className='faq--icon' /> : <KeyboardArrowRightIcon className='faq--icon' />}</h3>
        {selectTab === '7' ? (
          <p>Yes, you will automatically receive any updates or new content added to the course during your
            access period.</p>
        ) : null}
        <hr />
        <h3 onClick={() => handleFaq('8')}>Is there a community or support group? {selectTab === '8' ? <KeyboardArrowDownIcon className='faq--icon' /> : <KeyboardArrowRightIcon className='faq--icon' />}</h3>
        {selectTab === '8' ? (
          <p>The ‘community’ section of the online portal is where you can interact with other property sourcers,
            find/sell your deals, and joint venture with others. You can even submit your deal to PMW’s own
            database of investors, if you’ve not already lined up your own investors, or you find a deal that
            doesn’t suit your own investors.</p>
        ) : null}
        <hr />
        <h3 onClick={() => handleFaq('9')}>What if I need help or have questions? {selectTab === '9' ? <KeyboardArrowDownIcon className='faq--icon' /> : <KeyboardArrowRightIcon className='faq--icon' />}</h3>
        {selectTab === '9' ? (
          <>
            <p>For any issues with your account or programme access, you can use the “PMW Helpdesk” link
              inside the online portal. Or you can reach out to Team PMW on the following channels:</p>
            <ul>
              <li>Phone: <a href='tel:08002335596'>0800 233 5596</a></li>
              <li>Email: <a href='mailto:main"teampmw.com'>mail@teampmw.com</a></li>
              <li>WhatsApp/Text: 07893 945233</li>
            </ul>
          </>
        ) : null}
        <hr />
      </section>

    </section>
  )
}