import Header from "./Header";
import "./styles/home.scss";
import ReactPlayer from "react-player";
import thumb from "./images/SA_thumbnail.webp";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import paul from "./images/Paul_bio_top.webp";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Testimonials from "./pages/Testimonials";
import axios from "axios";
import moment from "moment";
import { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, Stack, FormGroup, FormControlLabel, Switch } from "@mui/material";
import square1 from './images/PMW-1.webp';
import square2 from './images/Paul-4.webp';
import square3 from './images/marketing.webp'
import square4 from './images/first_deal.webp'
import FAQ from "./pages/FAQ";
import modulesImg from './images/online_learning.webp';
import media from './images/media.webp';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Home() {
  const navigate = useNavigate();
  const today = moment();
  const url = "https://dev.pmwwebdesign.com";
  // const url = 'http://localhost:3999'

  const [showEvent, setShowEvent] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");
  const [shoTime, setShowTime] = useState("");
  const [showEverGreen, setShowEverGreen] = useState(false);
  const [background, setBackground] = useState('#fff');
  const [secBackground, setSecBackground] = useState('#EFF6FE')
  const [showNonEssential, setShowNonEssential] = useState(false)

  // COOKIES
  const [open, setOpen] = useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const calculateTimeLeft = () => {
    let event = new Date(timeLeft).getTime();
    let now = new Date().getTime();

    let distance = event - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return `${days}day, ${hours}h, ${minutes}min, ${seconds}secs`;
  };

  useEffect(() => {
    setTimeout(() => {
      setShowTime(calculateTimeLeft);
    }, 1000);
  });

  useEffect(() => {
    let cookies = localStorage.getItem('pmw_cookies')
    if (cookies) {
      handleClose()
    }
    console.log(window.innerWidth)

    axios
      .get(`${url}/psdt`)
      .then((res) => {
        let date = res.data.dates[res.data.dates.length - 1];

        // console.log(today.diff(moment(date.startDate), "seconds"));

        if (
          today.diff(moment(date.startDate), "seconds") > 0 &&
          today.diff(moment(date.endDate), "seconds") < 0
        ) {
          setShowEvent(true);
          setTimeLeft(date.endDate);
        } else if (today.diff(moment(date.endDate), "seconds") > 0) {
          localStorage.setItem('evergreen', true)
          setShowEverGreen(true);
          setBackground('#EFF6FE')
          setSecBackground('#fff')
        } else if (today.diff(moment(date.startDate), "seconds") < 0) {
          let blurs = document.getElementsByClassName('blur')
          for (let b = 0; b < blurs.length; b++) {
            blurs[b].style = 'filter: blur(10px);'
          }
          setTimeLeft(date.startDate);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCookies = () => {
    let pmw_cooks = true

    localStorage.setItem('pmw_cookies', pmw_cooks)
    handleClose();
  }


  const handleCustomise = () => {
    setShowNonEssential(true)
  }

  function gtag() { window.dataLayer.push(arguments); }


  const handleConsent = (cookie, value) => {
    if (value == false) {
      gtag('consent', 'update', {
        [cookie]: 'denied'
      })
    } else {
      gtag('consent', 'update', {
        [cookie]: 'granted'
      })
    }
  }

  const handleDecline = async () => {
    await gtag('consent', 'update', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied'
    });

    handleClose()
  }

  return (
    <main className="home__main">
      <Header timeLeft={timeLeft} />
      <section className="hero">
        <aside>
        <h1>Property Sourcing & Deal Trading Online Masterclass</h1>
        <p>
          With 20 years of experience in deal trading, I'm going to share all the insider knowledge you need to achieve financial freedom inside the next 6 - 12 months.
        </p>
        {showEvent ? (
          <>
            <p>Launch Offer Price & Bonuses Expires In: {shoTime}</p>
            <button
              className="enrol_button"
              onClick={() => navigate("/register")}
            >
              Get Instant Access Now
            </button>
          </>
        ) : showEverGreen ? (
          <button
            className="enrol_button"
            onClick={() => navigate("/register")}
          >
            Get Instant Access Now
          </button>
        ) : (
          <>
            <p style={{ fontWeight: 700 }}>Pre-Launch Offer Open In: {shoTime}</p>
            <button
              className="enrol_button"
              onClick={() => navigate("/register")}
            >
              Sign Up For Early Access
            </button>
          </>
        )} 
        </aside>
        <div className='player--wrapper'>
          <ReactPlayer
            url="https://vimeo.com/994057575/64200257f1?share=copy"
            controls
            style={{ aspecRatio: "16/9" }}
            className="video--player"
            light={thumb}
            width='100%'
            height='100%'

          />
        </div>



        {/* OLD HERO SECTION */}
        {/* <h1>Property Sourcing & Deal Trading Online Masterclass</h1>
        <p>
          With 20 years of experience in deal trading, I'm going to share all the insider knowledge you need to achieve financial freedom inside the next 6 - 12 months.
        </p>
        <div className='player--wrapper'>
          <ReactPlayer
            url="https://vimeo.com/994057575/64200257f1?share=copy"
            controls
            style={{ aspecRatio: "16/9" }}
            className="video--player"
            light={thumb}
            width='100%'
            height='100%'

          />
        </div>
        {showEvent ? (
          <>
            <p>Launch Offer Price & Bonuses Expires In: {shoTime}</p>
            <button
              className="enrol_button"
              onClick={() => navigate("/register")}
            >
              Get Instant Access Now
            </button>
          </>
        ) : showEverGreen ? (
          <button
            className="enrol_button"
            onClick={() => navigate("/register")}
          >
            Get Instant Access Now
          </button>
        ) : (
          <>
            <p style={{ fontWeight: 700 }}>Pre-Launch Offer Open In: {shoTime}</p>
            <button
              className="enrol_button"
              onClick={() => navigate("/register")}
            >
              Sign Up For Early Access
            </button>
          </>
        )} */}
        {/* END OF OLD HERO SECTION */}
      </section>
      {/* WHAT IS COVERED */}
      <section className="includedSection">
        <h2>What You're Going To Learn</h2>
        <hr></hr>

        <section className="included">
          <section className="column">
            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>The 5-Step Deal Trading Process</h6>
              </aside>
              <p>
                Get our 5-step process that takes you from evaluating a lead to closing the deal, enabling you to
                make immediate cash flow of £3,000 to £10,000 per deal.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Live Deal Case Studies</h6>
              </aside>
              <p>
                Follow real-time, step-by-step breakdowns of actual deals. Learn how these deals are structured to
                maximise cash flow and profitability.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Due Diligence & Refurb Pricing</h6>
              </aside>
              <p>
                Master our 5-step process to due diligence on properties, and learn how to price and manage
                renovations. Earning thousands by project managing refurb projects.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Proven Marketing Strategies</h6>
              </aside>
              <p>
                Access our tried-and-tested marketing strategies to source off-market property deals and attract
                investors willing to pay upfront for your property sourcing services.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Deal Structuring Tool</h6>
              </aside>
              <p>
                Utilise the PMW deal calculator, designed to help you effortlessly structure the most profitable
                deals.
              </p>
            </section>
          </section>

          <section className="column">
            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Company Setup and Compliance</h6>
              </aside>
              <p>
                Learn how to establish, automate, and maintain a fully legal compliant deal trading business. We also provide all necessary contracts and legal documents.
              </p>
            </section>

            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Business Setup and Scaling</h6>
              </aside>
              <p>
                Develop a solid foundation for launching, growing, and scaling your deal trading business, with
                insights tailored to each stage of your journey.
              </p>
            </section>

            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Joint Venture Opportunities</h6>
              </aside>
              <p>
                Gain exclusive access to joint venture opportunities with PMW, whether for funding your deals or
                leveraging our extensive investor network to close sales.
              </p>
            </section>


            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>12-Month Membership Support</h6>
              </aside>
              <p>
                Benefit from a full year of expert guidance, helping you structure and maximise the profitability of
                your deals.
              </p>
            </section>

            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Over 15 Hours of Comprehensive Content</h6>
              </aside>
              <p>
                Our online Masterclass is divided into easy to digest modules, making it straightforward and easy to
                follow.
              </p>
            </section>
          </section>
        </section>
      </section>
      <section className="what__is">
        <h2>How You Will Get Results</h2>
        <hr />
        <p>By enrolling in the most comprehensive property sourcing course available, you’ll gain in-depth knowledge on deal sourcing, including how to do due diligence, finding the right investors, structuring deals for maximum profit, and ensuring you are set up correctly and fully compliant. You'll also receive <span style={{ fontWeight: 700 }}>thousands of pounds worth</span> of legal templates, contracts, investor packs, and support.</p>
        <p>You’ll have exclusive access to the PMW investor database, allowing you to sell your deals directly to our network of ready buyers. There's no better way to kickstart your property journey and accelerate your success</p>
        {showEvent || showEverGreen ? (
          <button
            className="enrol_button"
            onClick={() => navigate("/register")}
          >
            Get Instant Access Now
          </button>
        ) : (
          <button
            className="enrol_button"
            onClick={() => navigate("/register")}
          >
            Sign Up For Early Access
          </button>
        )}
      </section>
      {/* WHO IS IT FOR */}
      <section className="includedSection">
        <h2>Who is it for?</h2>
        <hr></hr>
        <section className="included">
          <section className="column">
            <section className="sectioncontent left wiif">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Just Getting Started?</h6>
              </aside>
              <p>
                If you’re wanting to get involved in property but unsure how to
                start, have little to no funds or your credit isn't good, this masterclass is
                perfect. You'll learn how to get started regardless of your
                circumstances, and leave with a blueprint for{" "}
                <span style={{ fontWeight: 700 }}>
                  generating high monthly cashflow.
                </span>
              </p>
            </section>

            <section className="sectioncontent left wiif">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Struggling to Find Property Deals?</h6>
              </aside>
              <p>
                Learn the step-by-step ‘Deal Sourcing Blueprint’ that has worked
                for us for almost two decades. You’ll get a{" "}
                <span style={{ fontWeight: 700 }}>
                  proven system to find great property deals
                </span>
                especially how to source off market property. Whether you keep the deals or sell them on for thousands of pounds, you'll know how to find the deals to make this happen.
              </p>
            </section>
          </section>

          <section className="column">
            <section className="sectioncontent right wiif">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Looking For Serious Cashflow?</h6>
              </aside>
              <p>
                The greatest challenge for most property investors is the lack
                of funds to start or grow. At this masterclass, you’ll learn to
                structure every deal for maximum profit, giving your business a
                constant flow of funds. Our students{" "}
                <span style={{ fontWeight: 700 }}>average £8,000 per deal</span>
                , and you'll get the exact step by step guide on how to do the same.
              </p>
            </section>

            <section className="sectioncontent right wiif">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>
                  Want To Become A Full-Time Property Investor or Developer?
                </h6>
              </aside>
              <p>
                Many of our students have used this strategy to replace their income inside of 3 - 6 months and, by following the 'Sourcing Blueprint', have built a <span style={{ fontWeight: 700 }}>6 figure business within 12 months</span>.
              </p>
            </section>
          </section>
        </section>
      </section>
      {/* MODULES */}
      <section className="modules">
        <section className="module--text">
          <h2>15+ Hours of In-Depth, Easy-to-Follow Modules</h2>
          <hr />
          <img src={modulesImg} alt="Online Learning" id="show--mobile" />
          <p>Gain access to <strong>over 15 hours</strong> of clear, comprehensive modules, packed with every resource you’ll
            need to launch your property deal sourcing and trading business.</p>
          {showEvent || showEverGreen ? (
            <>
              <p>And all of this is available for the incredible investment of just <strong>£495 + VAT</strong>.</p>
              <p style={{ marginBottom: 0 }}> That’s a ROI of over <strong>2,000%</strong> once you close your first deal, </p>
              <p style={{ marginTop: 0 }}>earning <strong>£10,000</strong> by
                following our proven strategies.</p>
            </>
          ) : (
            <p>And all of this is available for a fraction of the normal retail price of £2000 <strong>ONLY for those on our pre-launch list</strong>.</p>

          )}
          <h5>An Absolute No-Brainer!</h5>
        </section>
        <img src={modulesImg} alt="Online Learning" id="hide--mobile" />
      </section>
      {/* END MODULES */}

      <section className="enrolments" >
        {showEvent || showEverGreen ? (
          <h2>Deal Trading Can Make You A Serious Income.
            Now Officially Open For Enrolment!</h2>

        ) : (
          <h2>Deal Trading Can Make You A Serious Income. Join The List To Get The Launch Discount Price & Bonuses </h2>
        )}
        <img src={media} alt="pmw media" />
        <section className="payment__options">
          <aside className="payment--box">
            <h4>3 Payments of</h4>
            <h2 className="blur">£165 <span>+ VAT</span></h2>
            {showEvent || showEverGreen ? (
              <>
                <button onClick={() => navigate("/register")}>Spread the cost with Klarna</button>
                <small>* Conditions Apply</small>
              </>
            ) : (
              <button onClick={() => navigate("/register")}>Sign Up For Early Access</button>
            )}
          </aside>
          <aside className="payment--box">
            <h4>1 Payment of</h4>
            <h2 className="blur">£495 <span>+ VAT</span></h2>
            {showEvent || showEverGreen ? (
              <button onClick={() => navigate("/register")}>Buy Now</button>
            ) : (
              <button onClick={() => navigate("/register")}>Sign Up For Early Access</button>
            )}
          </aside>
        </section>
      </section>



      {/* ADDED EXTRAS */}
      {!showEverGreen ? (
        <>
          <section className="extras">
            {showEvent ? (
              <h2>Exclusive Limited-Time Launch Bonuses to Accelerate Your Success and Maximise Your ROI</h2>
            ) : (
              <h2>Exclusive Limited time bonuses only available for those on the pre launch list</h2>
            )}
            <hr />
            <section className="extra__boxes">
              <aside className="text__side">
                <h5>Bonus 01</h5>
                <h4>Live In-Person Event</h4>
                <h6>(£997 Value)</h6>
                <p>Option to attend our in-person 2-day Live Workshop during your 12-months membership, as a bonus to the online programme. Multiple dates to choose from.</p>
              </aside>
              <img src={square2} alt="In Person Event" />
            </section>
            <section className="extra__boxes">
              <aside className="text__side">
                <h5>Bonus 02</h5>
                <h4>Exclusive Access to Our Private Community</h4>
                <h6>(£397 Value)</h6>
                <p>Receive 12 months access to our private community, co-source, collaborate on deals and get all of your questions answered. </p>
              </aside>
              <img src={square1} alt="Private Community" />
            </section>
            <section className="extra__boxes">
              <aside className="text__side">
                <h5>Bonus 03</h5>
                <h4>Our Proven Marketing Assets</h4>
                <h6>(£497 Value)</h6>
                <p>Get our tried-and-tested Marketing Assets, templates and Ad Sets, proven to convert with thousands of deals already secured.</p>
              </aside>
              <img src={square3} alt="In Person Event" />
            </section>
            <section className="extra__boxes">
              <aside className="text__side">
                <h5>Bonus 04</h5>
                <h4>Help Structuring Your First Deal</h4>
                <h6>(Priceless!!!)</h6>
                <p>We will help you structure your first deal ensuring you 10x your investment into the online programme!</p>
              </aside>
              <img src={square4} alt="In Person Event" />
            </section>

            {showEvent ? (
              <>
                <h5>Bonuses Are Only Available For A Limited Time: {shoTime}</h5>
                <button
                  className="enrol_button"
                  onClick={() => navigate("/register")}
                >
                  Get Instant Access Now
                </button>
              </>
            ) : showEverGreen ? (
              <button
                className="enrol_button"
                onClick={() => navigate("/register")}
              >
                Get Instant Access Now
              </button>
            ) : (
              <button
                className="enrol_button"
                onClick={() => navigate("/register")}
              >
                Sign Up For Early Access
              </button>
            )}
          </section>
        </>
      ) : null}
      <section className="learn" style={{ backgroundColor: background }}>
        <h2>
          By the end of this programme, you will
        </h2>
        <hr />
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Have a Clear, Step-by-Step Process to Follow</h4>
            <p>Gain absolute clarity on how to source properties, secure deals, and structure them to earn
              thousands of pounds by trading them on. You'll know exactly what to do at every stage.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Know How to Set Up a Fully Compliant Deal Trading Business</h4>
            <p>Establish yourself as a professional deal trader, ensuring your business is fully compliant and
              operates smoothly. You'll learn the right way to run your business, giving you a competitive edge.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Mastered Due Diligence and Refurb Pricing</h4>
            <p>You'll confidently assess whether a deal is worth pursuing and know how to structure it for
              maximum profit. Additionally, you'll learn how to accurately price renovations and earn project
              management fees, adding even more to your income.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Know How to Find Off-Market Property Deals</h4>
            <p>Develop the skills to uncover the best off-market deals, allowing you to either build your portfolio,
              flip properties, or trade the deals you don't want—all while making thousands of pounds with each
              deal traded.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Discovered How to Attract the Right Investors</h4>
            <p>Identify and attract the ideal investors, enabling you to work smarter, not harder. You'll learn how to
              focus on quality partnerships that significantly boost your earnings compared to the average
              property sourcer.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Gain Unshakable Confidence in Your Abilities</h4>
            <p>With the right knowledge and a clear step-by-step process to guide you, you'll be empowered to
              take action and start making life-changing money within the next few months.</p>
            <hr />
          </section>
        </section>

      </section>
      {!showEverGreen ? (
        <section className="values" style={{ backgroundColor: secBackground }}>
          <h2>When you add it all up, that's a total value of £1,900</h2>
          <hr />
          <section className="values__main">
            <aside>
              <div className="aside--row">
                <ArrowCircleRightIcon className="aside--row--icon" />
                <p>Live In-Person Event <span>(£997 Value)</span></p>
              </div>
              <div className="aside--row">
                <ArrowCircleRightIcon className="aside--row--icon" />
                <p>Exclusive Access To Our Private Community <span>(£397 Value)</span></p>
              </div>
            </aside>
            <aside>
              <div className="aside--row">
                <ArrowCircleRightIcon className="aside--row--icon" />
                <p>Proven Marketing Assets <span>(£497 Value)</span></p>
              </div>
              <div className="aside--row">
                <ArrowCircleRightIcon className="aside--row--icon" />
                <p>Help Structuring Your First Deal <span>(Priceless!!!)</span></p>
              </div>
            </aside>
          </section>
          <img src={media} alt="pmw media" />
          {showEverGreen || showEverGreen ? (
            <h5>But you can get instant access today for... <ArrowDownwardIcon className='values--icon' /></h5>
          ) : (
            <h5>Only available for those on the pre-launch list <ArrowDownwardIcon className='values--icon' /></h5>
          )}
          <section className="payment__options">
            <aside className="payment--box" style={{ backgroundColor: background }}>
              <h4>3 Payments of</h4>
              <h2 className="blur">£165 <span>+ VAT</span></h2>
              {showEvent || showEverGreen ? (
                <>
                  <button onClick={() => navigate("/register")}>Spread the cost with Klarna</button>
                  <small>* Conditions Apply</small>
                </>
              ) : (
                <button onClick={() => navigate("/register")}>Sign Up For Early Access</button>
              )}
            </aside>
            <aside className="payment--box" style={{ backgroundColor: background }}>
              <h4>1 Payment of</h4>
              <h2 className="blur">£495 <span>+ VAT</span></h2>
              {showEvent || showEverGreen ? (
                <button onClick={() => navigate("/register")}>Buy Now + Get The Extra Bonuses</button>
              ) : (
                <button onClick={() => navigate("/register")}>Sign Up For Early Access</button>
              )}
            </aside>
          </section>
        </section>
      ) : null}
      <Testimonials background={secBackground} />
      <section className="bio" style={{ backgroundColor: background }}>
        <img src={paul} alt="Paul McFadden" />
        <article>
          <h2>Paul McFadden</h2>
          <p>
            Renowned entrepreneur, award-winning business coach, and leading
            authority on wealth creation.
          </p>
          <p>
            Starting his property journey at the age of 19, Paul built a
            multi-million-pound property portfolio, achieving financial freedom
            and millionaire status by 26. Today, he dedicates his time to
            mentoring others, empowering thousands to transform their lives and
            attain financial freedom through property investment.
          </p>
          <p>
            Throughout his career, Paul’s remarkable achievements have made him
            a globally sought-after speaker at prestigious property and business
            events. His expertise has also earned him an honorary membership in
            Robert Kiyosaki’s Wealth Master Panel.
          </p>
        </article>
      </section>
      <FAQ background={secBackground} />
      <Footer />
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            We Use Cookies
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We use cookies to ensure the best experience on our website. These help us improve our site performance, personalise content, and deliver targeted ads.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            By clicking "Accept All Cookies," you agree to the use of all cookies. You can manage your preferences by selecting "Customise Settings" or view our full Cookie Policy for more information.
          </Typography>
          {showNonEssential ? (
            <Stack>
              <FormGroup>
                <FormControlLabel disabled control={<Switch defaultChecked />} label="Essential Cookies" />
                <FormControlLabel control={<Switch defaultChecked />} label="Performance Cookies" onChange={(e) => handleConsent('performance', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Functionality Cookies" onChange={(e) => handleConsent('ad_personalization', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Analytics Cookies" onChange={(e) => handleConsent('analytics_storage', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Adverstising Cookies" onChange={(e) => handleConsent('ad_storage', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Social Media Cookies" onChange={(e) => handleConsent('ad_user_data', e.target.checked)} />
              </FormGroup>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '5%' }}>
                <Button variant="outlined" color="success" onClick={handleCookies}>Accept Custom</Button>
                <Button variant="outlined" color="success" onClick={handleCookies}>Accept All</Button>
              </Stack>

            </Stack>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '5%' }}>
              <Button variant="outlined" color="success" onClick={handleCookies}>Accept Cookies</Button>
              <Button variant="outlined" color="primary" onClick={handleCustomise}>Customise Settings</Button>
              <Button variant="outlined" color="success" onClick={handleDecline}>Decline Non-Essential</Button>

            </Stack>

          )}

        </Box>
      </Modal>
    </main>
  );
}
