import { useState, useEffect } from "react";
import { Alert, FormControlLabel, Checkbox } from '@mui/material';

import { Elements, } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./CheckOutForm";
import axios from 'axios';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripe = await loadStripe('pk_live_aRr3qYrpVTbyNgBbB7t0jqiy');


export default function Payment(props) {
  // alerts
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [showAlert, setShowAlert] = useState(false)
  const url = 'https://dev.pmwwebdesign.com'
  // const url = 'http://localhost:3999'
  const [secret, setSecret] = useState('');
  const [options, setOptions] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [email, setEmail] = useState('')
  const [confirm, setConfirm] = useState(false)

  useEffect(() => {
    let secret;
    let amouunt = {
      amount: 59400
    }
    axios.post(`${url}/psdt/secret`, amouunt).then((res) => {
      // console.log(res.data.client_secret)
      setSecret(res.data.client_secret)
      secret = res.data.client_secret
      setOptions({ clientSecret: secret, })
      setShowLoader(false)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  const manageError = (error) => {
    setMessage(error)
    setSeverity('error')
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 2500)
  };

  const handleDetails = (value) => {
    setEmail(value)
  }

  const handleTc = (value) => {
    if (value == true) {
      setShowLoader(false)
    }
  }

  return (
    <section className="payment__form">
      {showLoader ? (
        <h2>Loading</h2>
      ) : (
        <>
          <h2>Payment Details</h2>
          <section className="billing__details">

            <section className="form--row">
              <div>
                <label for="emailI">Email <span id="email">Required field</span></label>
                <input type="email" id="emailI" onChange={(e) => handleDetails(e.target.value)} />
              </div>
            </section>
          </section>
          <Elements stripe={stripe} options={options}>
            <CheckoutForm error={manageError} payment={props.payment} email={email} confirm={confirm} />
          </Elements>
          <section className="form--row">
              <FormControlLabel control={<Checkbox />} label="I confirm I have read and agree to the terms & conditions" onChange={(e) => handleTc(e.target.checked)} />
            </section>

        </>
      )}
      {showAlert ? (
        <Alert severity={severity} sx={{ margin: '1% auto', width: '80%' }} variant="filled">{message}</Alert>
      ) : null}


    </section>
  )

}