import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './components/Home';
import Register from './components/pages/Register';
import ThankYou from './components/pages/ThankYou';
import Privacy from './components/pages/Privacy';
import NotFound from './components/pages/Notfound';
import Download from './components/pages/Download';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="register" element={<Register />} />
          <Route path="thank-you" element={<ThankYou />} />
          <Route path='privacy-policy' element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
          <Route path="download" element={<Download />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
