import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import AddressForm from './AddressForm';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = { mode: 'billing' };
  console.log(props.evergreen)

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://propertydealtrading.com/thank-you?client=true",
        // return_url: "http://localhost:3000/thank-you?client=true",
      },
    });

    if (result.error) {
      props.error(result.error.message)

    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      props.submit()
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <AddressForm email={props.email} error={props.error} />
      <br />
      <PaymentElement />
      <button disabled={!stripe || !props.email} className='submit__bttn'>Submit</button>
    </form>
  )
};

export default CheckoutForm;