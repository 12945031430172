import Footer from '../Footer';
import Header from '../Header';
import '../styles/notFound.scss';
import Paul from '../images/Paul_lost.webp';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate()
  return (
    <main className="not_found__main">
      <Header page='not_found' />
      <h2>Oh Oh!! Looks like you have lost your deal!</h2>
      <section className="not__found">
        <img src={Paul} alt='Paul lost' />
        <section className='notFound--text'>
          <h3>Do you want to be part of the Property Source Deal Trading Masterclass?</h3>
          <h3 onClick={() => navigate('/')}>Get me to the home page!</h3>
        </section>
      </section>
      <Footer />
    </main>
  )
}