import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

export default function MiniCheckOut(props){
  const stripe = useStripe();
  const elements = useElements();
  const options = { mode: 'billing' };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://propertydealtrading.com/thank-you",
      },
      redirect: "if_required",
    });

    if (result.error) {
      props.error(result.error.message)

    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      props.submit()
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe} className='submit__bttn'>Submit</button>
    </form>
  )

}