import logo from "./images/logo.png";
import "./styles/navFooter.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

export default function Header(props) {
  const navigate = useNavigate();
  const url = "https://dev.pmwwebdesign.com";
  // const url ='http://localhost:3999'

  const today = moment();
  const [showEvent, setShowEvent] = useState(false)
  const [timeLeft, setTimeLeft] = useState('');
  const [showEverGreen, setShowEverGreen] = useState(false)
  const [thankYou, setThankYou] = useState(false)


  const calculateTimeLeft = () => {
    let event = new Date(props.timeLeft).getTime();
    let now = new Date().getTime();

    let distance = event - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return `${days}day, ${hours}h, ${minutes}min, ${seconds}secs`;
  };

  useEffect(() => {
    if (props.page) {
      setThankYou(true)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft);
    }, 1000);
  });

  useEffect(() => {
    axios
      .get(`${url}/psdt`)
      .then((res) => {

        let date = res.data.dates[res.data.dates.length - 1]

        // console.log(date.endDate)

        if ((today.diff(moment(date.startDate), 'seconds') > 0) && (today.diff(moment(date.endDate), 'seconds') < 0)) {

          setShowEvent(true);
        } else if (today.diff(moment(date.endDate), 'seconds') > 0) {
          setShowEverGreen(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
      console.log(window.innerWidth)
  }, []);

  return (
    <nav>
      <img src={logo} alt='pmw logo' onClick={() => navigate('/')} />
      {!thankYou ? (
        <section className='button__area'>
          {showEvent ? (
            <>
              <p>Bonuses Available Until: {timeLeft}</p>
              <button
                className="enrol_button"
                onClick={() => navigate("/register")}
              >
                Get Instant Access Now
              </button>
            </>
          ) : showEverGreen ? (
            <button className='enrol_button' onClick={() => navigate('/register')}>Get Instant Access Now</button>
          ) : (
            <>
            {/* <p>Pre-Launch In: {timeLeft}</p> */}
            <button className='enrol_button' onClick={() => navigate('/register')}>Sign Up For Early Access</button>
            </>
          )}
        </section>

      ) : null}
    </nav>
  );
}
