import Footer from "../Footer";
import Header from "../Header";
import '../styles/thanks.scss';
import axios from "axios";
import { useEffect, useState } from "react";
import { Box, Stack, Button, Typography, Modal } from '@mui/material';
import MiniCheckOut from "./MiniCheckOut";
import { Elements, } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Alert from '@mui/material/Alert';
import event from '../images/Event.webp';
const stripe = await loadStripe('pk_live_aRr3qYrpVTbyNgBbB7t0jqiy');


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '1rem',
  boxShadow: 24,
  p: 4,
};


export default function ThankYou() {
  const url = 'https://dev.pmwwebdesign.com'
  // const url = 'http://localhost:3999'
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    // localStorage.clear();
  };
  const [showPayment, setShowPayment] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [options, setOptions] = useState({});
  // alerts
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [showAlert, setShowAlert] = useState(false)

  const [bump, setBump] = useState(false)



  useEffect(() => {
    let details = localStorage.getItem('client')
    let finalDetails = JSON.parse(details)
    if (details) {
      setClientDetails(finalDetails)
      sendZap(finalDetails)
      checkEvent()
    }
    // window.addEventListener('beforeunload', handleOpen())
  }, [])

  const checkEvent = async () => {
    let event = localStorage.getItem('evergreen')
    const params = new URLSearchParams(window.location.search)
    const client = params.get("client")
    console.log(client)
    if (event && client) {
      setBump(true)
    }
    //  else {
    //   localStorage.clear();
    // }
  }

  const sendZap = async (details) => {
    await axios.post(`${url}/psdt/zap`, details)
      .then((res) => {
        console.log(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }

  const handleYes = async () => {
    let amount = {
      amount: 23400
    }
    await axios.post(`${url}/psdt/secret`, amount).then((res) => {
      let secret = res.data.client_secret
      setOptions({ clientSecret: secret, })
      setShowPayment(true)
    }).catch((err) => {
      console.log(err)
    })

  }

  const manageError = (error) => {
    setMessage(error)
    setSeverity('error')
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 2500)
  };

  const handleSubmit = () => {
    // send email to admin and clears local storage
    axios.post(`${url}/psdt/email`, clientDetails)
      .then((res) => {
        localStorage.clear()
        setBump(false)
      }).catch((err) => {
        console.log(err)
      })
  }


  return (
    <main className="thank__you">
      <Header page="thank-you" />
      <section className="thanks">
        {!bump ? (
          <h2>Thank You</h2>
        ) : (
          <section className="bump__sec">
            <h2>Before you go!</h2>
            <section className="bump">
              <aside className='bump__text'>
                <img src={event} alt="in-person event" />
                <small>* The event will take place quarterly at the PMW HQ, once you have purchase this our team will contact you with the next date</small>
              </aside>
              <aside className='bump__text'>
                <h3>Don't miss this opportunity</h3>
                <h3>Attend our in person event?</h3>
                <h4>This opportunity can only be access now, don't miss your chance</h4>
                <h4>Normally priced at £997 + VAT</h4>
                <h4>Get it now for ONLY £195 + VAT!</h4>
                <h4 style={{color:'#E8160C'}}>You will not get this chance again</h4>
                {!showPayment ? (
                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '5%' }}>
                    <Button variant="contained" onClick={handleYes} color="primary">Count Me In</Button>
                    {/* <Button onClick={handleClose} variant="outlined" color="error">No Thank You, next time</Button> */}
                  </Stack>
                ) : null}
                {showPayment ? (
                  <Elements stripe={stripe} options={options}>
                    <MiniCheckOut error={manageError} submit={handleSubmit} />
                  </Elements>
                ) : null}
                {showAlert ? (
                  <Alert severity={severity} sx={{ margin: '1% auto', width: '80%' }} variant="filled">{message}</Alert>
                ) : null}
              </aside>
            </section>
          </section>
        )}
      </section>
      <Footer />
    </main>
  )
}