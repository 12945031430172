import Footer from '../Footer';
import Header from '../Header';
import '../styles/download.scss';
import { useState } from 'react';
import { TextField, Alert } from '@mui/material';
import axios from 'axios';

export default function Download() {
  const [email, setEmail] = useState('');
  const [showDownload, setShowDownload] = useState(false)
  const url = 'https://dev.pmwwebdesign.com'
  // const url = 'http://localhost:3999'

  // alerts
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [showAlert, setShowAlert] = useState(false)

  const submitEmail = async () => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

    if (email === "") {
      setSeverity('warning')
      setMessage("Please insert your email")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2500)
    } else if (!email.match(regex)) {
      setSeverity('warning')
      setMessage("Please insert a valid email")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2500)
    } else {
      let data = {
        email: email,
      }
      await axios.post(`${url}/psdt/save-email`, data)
        .then((res) => {
          setShowDownload(true)
        }).catch((err) => {
          setSeverity('error')
          setMessage("Something went wrong, please try again")
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false)
          }, 2500)

        })
    }
  }




  return (
    <main className="download__main">
      <Header />
      <section className='download'>
        {showDownload ? (
          <>
            <h2>Download NOW</h2>
            <a href="https://drive.google.com/file/d/11ctrtEDmbsu_WqQxPsQGmUpjfQsdpDCU/view" target="_blank" rel='noreferrer'>Download</a>
          </>
        ) : (
          <>
            <h2>do you want our property sourcing guide?</h2>
            <p>If you want to start sourcing properties and get your property journey started, download our guide.</p>
            <TextField label="Insert Your Email" variant="outlined" className='email--input' onChange={(e) => setEmail(e.target.value)} />
            <button onClick={submitEmail}>Get The Guide</button>
          </>
        )}
        {showAlert ? (
          <Alert severity={severity} sx={{ margin: '1% auto', width: '80%' }} variant="filled">{message}</Alert>
        ) : null}
      </section>
      <Footer />
    </main>
  )
}