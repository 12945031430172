import Footer from "../Footer";
import Header from "../Header";
import { useState, useEffect } from "react";
import '../styles/form.scss';
import axios from "axios";
import moment from 'moment';
import Payment from "./Payment";

export default function Register() {
  const url = 'https://dev.pmwwebdesign.com';
  // const url = 'http://localhost:3999'
  const today = moment();
  const [showEvent, setShowEvent] = useState(false)
  const [timeLeft, setTimeLeft] = useState('');
  const [showEverGreen, setShowEverGreen] = useState(false)
  const [showing, setShowing] = useState('')



  useEffect(() => {
    axios.get(`${url}/psdt`)
      .then((res) => {
        let date = res.data.dates[res.data.dates.length - 1]

        if ((today.diff(moment(date.startDate), 'seconds') > 0) && (today.diff(moment(date.endDate), 'seconds') < 0)) {
          setShowEvent(true);
          setTimeLeft(date.endDate)
          setShowing('Event')
        } else if (today.diff(moment(date.endDate), 'seconds') > 0) {
          setShowEverGreen(true)
          setShowing('Evergreen')
        }
      }).catch((err) => {
        console.log(err)
      })
  }, [])


  return (
    <main className="register__main">
      <Header timeLeft={timeLeft} />
      {showEvent || showEverGreen ? (
        <Payment payment={showing}/>
      ) : (
        <>
          <h2>Sign Up for early access & bonuses only for those on the pre launch list</h2>
          <iframe
            src="https://api.leadconnectorhq.com/widget/form/o7kwj2XsL1RS92fJmbyN"
            style={{ width: "100%", height: "100%", border: "none", borderRadius: "3px" }}
            id="inline-o7kwj2XsL1RS92fJmbyN"
            data-layout="{'id':'INLINE'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="Form 2"
            data-height="432"
            data-layout-iframe-id="inline-o7kwj2XsL1RS92fJmbyN"
            data-form-id="o7kwj2XsL1RS92fJmbyN"
            title="Form 2"
          >
          </iframe>
        </>
      )}
      <Footer />
      <script src="https://link.msgsndr.com/js/form_embed.js"></script>
    </main>
  )
}